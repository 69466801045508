import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex full-width flex-center"
}

import {
	IMediaWithUrls,
	isImageMedia,
	isVideoMedia,
} from '@interfaces/media.interface'
import { ref } from 'vue'

type Props = {
	media: IMediaWithUrls[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'media-carousel',
  props: {
    media: {}
  },
  setup(__props: any) {

const props = __props

const fullscreen = ref(false)

const slide = ref(0)
const isLoading = ref(false)
const hasArrows = props.media.length > 1

function mediaPreviewUrl(m: IMediaWithUrls): string | undefined {
	const { kind } = m
	switch (kind) {
		case 'image':
			return m.url
		case 'video':
			return m.previewUrl ?? undefined
	}
}

return (_ctx: any,_cache: any) => {
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_video = _resolveComponent("q-video")!
  const _component_q_carousel_slide = _resolveComponent("q-carousel-slide")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_carousel_control = _resolveComponent("q-carousel-control")!
  const _component_q_carousel = _resolveComponent("q-carousel")!

  return (isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_circular_progress, {
          size: "lg",
          class: "flex",
          indeterminate: "",
          style: {"color":"var(--q-rose)"}
        })
      ]))
    : (_openBlock(), _createBlock(_component_q_carousel, {
        key: 1,
        swipeable: "",
        animated: "",
        modelValue: slide.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((slide).value = $event)),
        fullscreen: fullscreen.value,
        "onUpdate:fullscreen": _cache[2] || (_cache[2] = ($event: any) => ((fullscreen).value = $event)),
        thumbnails: "",
        infinite: "",
        arrows: hasArrows,
        class: "full-width",
        style: {"border-radius":"5px"}
      }, {
        control: _withCtx(() => [
          _createVNode(_component_q_carousel_control, {
            position: "top-right",
            offset: [18, 18]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                push: "",
                round: "",
                dense: "",
                color: "white",
                "text-color": "primary",
                icon: fullscreen.value ? 'fullscreen_exit' : 'fullscreen',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (fullscreen.value = !fullscreen.value))
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.media, (m, idx) => {
            return (_openBlock(), _createBlock(_component_q_carousel_slide, {
              key: idx,
              name: idx,
              class: "fullscreen-slide",
              "img-src": mediaPreviewUrl(m)
            }, {
              default: _withCtx(() => [
                (_unref(isImageMedia)(m))
                  ? (_openBlock(), _createBlock(_component_q_img, {
                      key: 0,
                      loading: "lazy",
                      src: mediaPreviewUrl(m)
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true),
                (_unref(isVideoMedia)(m))
                  ? (_openBlock(), _createBlock(_component_q_video, {
                      key: 1,
                      loading: "lazy",
                      src: m.url,
                      class: "full-width full-height"
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["name", "img-src"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "fullscreen"]))
}
}

})