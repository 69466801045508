import { defineStore } from 'pinia'

import { API } from 'boot/axios'
import { IMedia, IMediaWithUrls } from '@interfaces/media.interface'

export const useMediaStore = defineStore('mediaStore', {
	actions: {
		async getImageUrl(
			imageId: number,
			width?: number,
			height?: number,
		): Promise<string> {
			return API.imageApi
				.imageControllerResize(imageId, width, height)
				.then((r) => r.data)
		},
		async extendMediaWithUrl(media: IMedia): Promise<IMediaWithUrls> {
			if (media.kind === 'image') {
				return this.getImageUrl(media.id).then((url) => ({ ...media, url }))
			}
			const previewUrl = media.previewId
				? await this.getImageUrl(media.previewId)
				: null
			return { ...media, previewUrl }
		},
	},
})
