import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useUiStore } from 'stores/ui.store'
import { onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'dark-mode-toggle',
  setup(__props) {

const uiStore = useUiStore()

onMounted(() => {
	uiStore.setDarkMode(uiStore.darkMode)
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    "fab-mini": "",
    flat: "",
    icon: _unref(uiStore)?.darkMode ? 'ion-sunny' : 'ion-moon',
    onClick: _unref(uiStore).toggleDarkMode
  }, null, 8, ["icon", "onClick"]))
}
}

})