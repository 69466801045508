import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from 'assets/images/examples/person-image.jpg'


const _hoisted_1 = { class: "flex row card" }
const _hoisted_2 = { class: "row col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 q-px-xs q-mb-sm" }
const _hoisted_3 = { class: "tags absolute-top-right gap-2" }
const _hoisted_4 = { class: "flex row tags absolute-bottom-right gap-2" }
const _hoisted_5 = { class: "column col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 gap-5 q-px-sm q-mb-sm" }
const _hoisted_6 = { class: "flex row gap-2" }
const _hoisted_7 = { class: "row col-12" }
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "row col-12 q-mb-sm" }
const _hoisted_10 = { class: "address" }
const _hoisted_11 = { class: "row buttons no-wrap gap-5 full-width" }
const _hoisted_12 = { class: "row buttons gap-5 full-width q-ma-xs" }
const _hoisted_13 = { class: "flex row prices q-py-xs" }
const _hoisted_14 = { class: "row col-6 col-xs-12 q-pa-xs" }
const _hoisted_15 = { class: "row col-6 col-xs-12 q-pa-xs" }
const _hoisted_16 = { class: "row col-6 col-xs-12 q-pa-xs" }
const _hoisted_17 = { class: "row col-6 col-xs-12 q-pa-xs" }

import { City, District, Tag } from 'src/common/axios-client'
import poleeIcon from '../common/polee-icon.vue'
import rankIcon from '../common/rank-icon.vue'
import personTag from './person/person-tag.vue'
import healthIcon from './health-icon.vue'
import { ref } from 'vue'
import { Slugs } from 'src/pages/public/slug.map'


export default /*@__PURE__*/_defineComponent({
  __name: 'person-card',
  setup(__props) {

const city = ref<City>({ id: 1, name: 'Москва', regionId: -1, slug: 'moskva' })
const district = ref<District | null>({
	id: 12,
	cityId: 185,
	name: 'Юго-запад',
	slug: 'yog',
})

const phoneNumber = ref<string | null>(null)
const telegramAccount = ref<string | null>('foo')
const whatsappNumber = ref<string | null>('+7')
const poleeAccount = ref<string | null>('goo')

const tags: Tag[] = [
	{
		id: 1,
		excluded: [],
		isPartner: false,
		isStarred: true,
		name: 'Индивидуалка',
		slug: 'indi',
	},
	{
		id: 2,
		excluded: [],
		isPartner: false,
		isStarred: false,
		name: 'Секс',
		slug: 'seks',
	},
	{
		id: 3,
		excluded: [],
		isPartner: false,
		isStarred: false,
		name: 'Эскорт',
		slug: 'eskort',
	},
	{
		id: 4,
		excluded: [],
		isPartner: false,
		isStarred: false,
		name: 'Целуюсь',
		slug: 'kisses',
	},
	{
		id: 5,
		excluded: [],
		isPartner: false,
		isStarred: false,
		name: 'МБР',
		slug: 'mbr',
	},
]

function showPhone() {
	phoneNumber.value = '+7 (999) 999-99-99'
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/1",
        class: "full-width"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_img, {
            loading: "lazy",
            class: "image",
            position: "0 0",
            src: _imports_0
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(rankIcon, { rank: 13 })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_q_chip, { class: "tag" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "play_arrow" })
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_chip, { class: "tag" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "comment" }),
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "q-ml-xs" }, "5", -1))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"flex row property\" data-v-cb33f974><div class=\"name\" data-v-cb33f974>Возраст</div><div class=\"q-ml-auto\" data-v-cb33f974>24</div></div><div class=\"flex row property\" data-v-cb33f974><div class=\"name\" data-v-cb33f974>Грудь</div><div class=\"q-ml-auto\" data-v-cb33f974>2</div></div><div class=\"flex row property\" data-v-cb33f974><div class=\"name\" data-v-cb33f974>Вес</div><div class=\"q-ml-auto\" data-v-cb33f974>50</div></div><div class=\"flex row property\" data-v-cb33f974><div class=\"name\" data-v-cb33f974>Рост</div><div class=\"q-ml-auto\" data-v-cb33f974>167</div></div>", 4)),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tags, (tag) => {
          return _createElementVNode("div", {
            key: tag.id
          }, [
            _createVNode(personTag, { tag: tag }, null, 8, ["tag"])
          ])
        }), 64))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_link, { to: "/id-007" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(healthIcon, { size: "xs" }),
            _cache[4] || (_cache[4] = _createTextVNode(" Вероника "))
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, {
          to: `${_unref(Slugs).CITY}/${city.value.slug}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(city.value.name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        (district.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[5] || (_cache[5] = _createTextVNode(", ")),
              _createVNode(_component_router_link, {
                to: `${_unref(Slugs).CITY}/${city.value.slug}/${_unref(Slugs).DISTRICT}/${district.value.slug}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(district.value.name), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_q_no_ssr, { class: "full-width" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          (phoneNumber.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                class: "button button-phone",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showPhone())),
                flat: "",
                href: `tel:${phoneNumber.value}`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "call",
                    class: "q-mr-xs"
                  }),
                  _createTextVNode(" " + _toDisplayString(phoneNumber.value), 1)
                ]),
                _: 1
              }, 8, ["href"]))
            : (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                class: "button button-phone",
                flat: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (showPhone()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "call",
                    class: "q-mr-xs"
                  }),
                  _cache[6] || (_cache[6] = _createTextVNode(" Показать телефон "))
                ]),
                _: 1
              })),
          (telegramAccount.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 2,
                flat: "",
                class: "button button-icon q-ml-auto",
                icon: "fa-brands fa-telegram",
                href: `https://t.me/${telegramAccount.value}`
              }, null, 8, ["href"]))
            : _createCommentVNode("", true),
          (whatsappNumber.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 3,
                flat: "",
                class: "button button-icon",
                icon: "fa-brands fa-whatsapp",
                href: `https://wa.me/${whatsappNumber.value}`
              }, null, 8, ["href"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (poleeAccount.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                flat: "",
                class: "button-polee",
                href: `https://polee/${poleeAccount.value}`
              }, {
                default: _withCtx(() => [
                  _createVNode(poleeIcon, { class: "q-mr-xs" }),
                  _cache[7] || (_cache[7] = _createTextVNode(" Polee "))
                ]),
                _: 1
              }, 8, ["href"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_icon, { name: "alarm" }),
          _cache[8] || (_cache[8] = _createTextVNode(" 1 час "))
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "q-ml-auto" }, "9 000 Р", -1))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_icon, { name: "alarm" }),
          _cache[10] || (_cache[10] = _createTextVNode(" 2 часа "))
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "q-ml-auto" }, "18 000 Р", -1))
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_icon, { name: "nights_stay" }),
          _cache[12] || (_cache[12] = _createTextVNode(" Ночь "))
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "q-ml-auto" }, "60 000 Р", -1))
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_icon, { name: "speed" }),
          _cache[14] || (_cache[14] = _createTextVNode(" Есть экспресс "))
        ])
      ])
    ])
  ]))
}
}

})