import { defineStore } from 'pinia'
import { API } from 'src/boot/axios'

// TODO: Implement real notifications
const entity = 'notification'
// const authStore = useAuthStore()

export const useNotificationStore = defineStore(entity, () => {
	function newNotifications(limit: number, offset: number) {
		return API.notificationsApi
			.notificationControllerFindNew(limit, offset)
			.then((e) => e.data)
	}

	function notifications(limit: number, offset: number) {
		return API.notificationsApi
			.notificationControllerFindAll(limit, offset)
			.then((e) => e.data)
	}

	function markAsSeen(id: string) {
		return API.notificationsApi.notificationControllerUpdateStatus(id, {
			status: 'readed',
		})
	}

	return { newNotifications, notifications, markAsSeen }
})
