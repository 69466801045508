export interface IImageMedia {
	kind: 'image'
	id: number
}

export interface IImageMediaWithUrl extends IImageMedia {
	url: string
}

export interface IVideoMedia {
	kind: 'video'
	url: string
	previewId: number | null
}

export interface IVideoMediaWithUrl extends IVideoMedia {
	previewUrl: string | null
}

export function isImageMedia(i: IMedia): i is IImageMedia {
	return i.kind === 'image'
}

export function isVideoMedia(i: IMedia): i is IVideoMedia {
	return i.kind === 'video'
}

export type IMedia = IImageMedia | IVideoMedia
export type IMediaWithUrls = IImageMediaWithUrl | IVideoMediaWithUrl
