<template>
	<img class="polee-icon" loading="lazy" src="~assets/images/polee.svg" />
</template>

<style scoped lang="scss">
.polee-icon {
	height: 15px;
	width: 15px;
}
</style>
