export const formatPhone = (phone: string): string | null => {
	const parts = phone.match(/^(\+[7]|)?(\d{3})(\d{3})(\d{4})$/) as string[]
	if (parts) {
		const code = parts[1] ?? ''
		return `${code} (${parts[2]}) ${parts[3]} ${parts[4]}`
	}
	return null
}

export const formatNumbers = (number: number) =>
	number.toString().replace(/\d+?(?=(?:\d{3})+$)/gim, '$& ')

export const formatDate = (timeStamp: Date) => {
	if (!timeStamp) return ''
	const d = new Date(timeStamp).getTime()
	return new Date(d - new Date().getTimezoneOffset() * 60 * 1000)
		.toISOString()
		.slice(0, -5)
		.split('T')
		.join(' ')
		.slice(0, -3)
}

export const formatTimeAgo = (dateString: string | undefined): string => {
	if (!dateString) return ''

	const date = new Date(dateString)
	const now = new Date()
	const elapsedMilliseconds = now.getTime() - date.getTime()
	const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)
	const elapsedMinutes = Math.floor(elapsedSeconds / 60)
	const elapsedHours = Math.floor(elapsedMinutes / 60)
	const elapsedDays = Math.floor(elapsedHours / 24)
	const elapsedWeeks = Math.floor(elapsedDays / 7)

	if (elapsedMilliseconds < 1000) {
		return 'Just now'
	} else if (elapsedSeconds < 60) {
		return elapsedSeconds + ' seconds ago'
	} else if (elapsedMinutes < 60) {
		return elapsedMinutes + ' minutes ago'
	} else if (elapsedHours < 24) {
		return elapsedHours + ' hours ago'
	} else if (elapsedDays < 7) {
		return elapsedDays + ' days ago'
	} else if (elapsedWeeks < 4) {
		return elapsedWeeks + ' weeks ago'
	} else {
		const monthsAgo = Math.floor(elapsedDays / 30)
		if (monthsAgo < 12) {
			return monthsAgo + ' months ago'
		} else {
			const yearsAgo = Math.floor(monthsAgo / 12)
			return yearsAgo + ' years ago'
		}
	}
}
