import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "32",
  height: "30",
  viewBox: "0 0 32 30",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = { fill: "#000" }
const _hoisted_4 = ["x"]

interface Props {
	readonly rank: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'rank-icon',
  props: {
    rank: {}
  },
  setup(__props: any) {

const props = __props

function getColor(): string {
	if (props.rank <= 3) return '#ffd178'
	if (props.rank <= 6) return '#ececec'
	return '#dba391'
}

function x(): number {
	if (props.rank >= 10) return 9
	return 12
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      x: ".375",
      width: "31.25",
      height: "30",
      rx: "15",
      fill: getColor()
    }, null, 8, _hoisted_2),
    _cache[0] || (_cache[0] = _createElementVNode("g", {
      opacity: ".2",
      fill: "#000"
    }, [
      _createElementVNode("path", { d: "M7.32 8.754c.232-.72.93-1.2 1.652-1.338.179.59.138 1.242-.107 1.807a1.998 1.998 0 01-1.473 1.219c-.23-.527-.276-1.145-.072-1.688zm-2.625 1.991c-.189-.717.127-1.487.643-1.992.563.467.918 1.173.956 1.903.014.55-.192 1.11-.593 1.492-.495-.319-.883-.822-1.006-1.403zm4.797-.251a2.674 2.674 0 00-1.483.557c-.214.177-.425.368-.56.614-.246.396-.347.88-.27 1.34.73.058 1.488-.247 1.94-.83.349-.478.466-1.1.373-1.68z" }),
      _createElementVNode("path", { d: "M6.949 10.768c.047.04.1.078.152.116.093.068.187.136.257.222-.605.883-.9 1.947-.993 3.005-.089 1.071.062 2.153.363 3.182.534 1.82 1.523 3.47 2.666 4.969l-.104.08c-.106.082-.21.163-.318.242-.84-1.108-1.597-2.287-2.156-3.563a11.531 11.531 0 01-.829-2.683c-.28-1.56-.155-3.21.473-4.674.136-.312.297-.614.489-.896zm-3.071.492a2.955 2.955 0 00-.223.56l-.023.11c-.087.456-.046.95.179 1.362a2.32 2.32 0 001.419 1.106c.264-.48.3-1.066.13-1.584a2.68 2.68 0 00-1.482-1.554z" }),
      _createElementVNode("path", { d: "M6.712 14.83c.282-.848 1.087-1.466 1.953-1.63.17.57.13 1.21-.162 1.733-.36.599-1.02.986-1.71 1.05a.22.22 0 00-.028.002c-.035.004-.075.008-.077-.038a1.961 1.961 0 01.024-1.116zm-1.695.144a2.652 2.652 0 00-1.553-.875c-.159.572-.11 1.212.192 1.73.384.621 1.097.998 1.82 1.029.195-.653-.005-1.386-.46-1.884zm2.482 1.843a2.69 2.69 0 011.627-1.34c.225.552.247 1.197 0 1.745-.314.658-.979 1.112-1.693 1.22a2 2 0 01.066-1.625zm1.984.883a2.678 2.678 0 00-1.172 1.73c-.097.556.04 1.155.4 1.596.656-.308 1.163-.936 1.273-1.657.078-.596-.13-1.204-.501-1.668zm-6.041-.719a2.636 2.636 0 011.738.36c.59.33 1.007.98 1.01 1.663-.564.14-1.188.096-1.696-.198-.665-.341-1.027-1.1-1.052-1.825zm2.92 2.845a2.647 2.647 0 00-1.753-.143c.11.707.547 1.404 1.232 1.673.515.23 1.116.222 1.644.04a.17.17 0 01.033-.014c.033-.011.07-.023.058-.061a1.994 1.994 0 00-1.214-1.495z" })
    ], -1)),
    _createElementVNode("g", _hoisted_3, [
      _createElementVNode("text", {
        x: x(),
        y: "18",
        style: {"font-size":"11px","font-weight":"600"}
      }, _toDisplayString(props.rank), 9, _hoisted_4)
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<g opacity=\".2\" fill=\"#000\"><path d=\"M23.68 8.754c-.232-.72-.93-1.2-1.652-1.338a2.651 2.651 0 00.107 1.807 1.998 1.998 0 001.473 1.219c.23-.527.276-1.145.072-1.688zm2.625 1.991c.189-.717-.127-1.487-.643-1.992a2.686 2.686 0 00-.956 1.903c-.014.55.192 1.11.593 1.492.495-.319.883-.822 1.006-1.403zm-4.797-.251c.536.029 1.06.229 1.483.557.214.177.425.368.56.614.246.396.347.88.27 1.34-.73.058-1.488-.247-1.94-.83-.349-.478-.466-1.1-.373-1.68z\"></path><path d=\"M24.051 10.768c-.047.04-.1.078-.152.116a1.322 1.322 0 00-.257.222c.605.883.9 1.947.993 3.005.089 1.071-.062 2.153-.363 3.182-.534 1.82-1.523 3.47-2.666 4.969l.104.08c.106.082.21.163.318.242.84-1.108 1.597-2.287 2.156-3.563.378-.858.67-1.758.828-2.683.28-1.56.156-3.21-.472-4.674a5.466 5.466 0 00-.489-.896zm3.071.492a3 3 0 01.223.56l.023.11c.087.456.046.95-.179 1.362a2.32 2.32 0 01-1.419 1.106 2.011 2.011 0 01-.13-1.584 2.68 2.68 0 011.482-1.554z\"></path><path d=\"M24.288 14.83c-.282-.848-1.087-1.466-1.953-1.63-.17.57-.13 1.21.162 1.733.36.599 1.02.986 1.71 1.05a.22.22 0 01.028.002c.035.004.075.008.077-.038a1.961 1.961 0 00-.024-1.116zm1.695.144c.395-.46.956-.773 1.553-.875.159.572.11 1.212-.192 1.73-.384.621-1.097.998-1.82 1.029-.195-.653.005-1.386.46-1.884zm-2.482 1.843a2.69 2.69 0 00-1.627-1.34c-.225.552-.247 1.197 0 1.745.314.658.979 1.112 1.693 1.22a2 2 0 00-.066-1.625zm-1.984.883a2.678 2.678 0 011.172 1.73 2.006 2.006 0 01-.4 1.596c-.656-.308-1.163-.936-1.273-1.657-.077-.596.13-1.204.502-1.668zm6.041-.719a2.636 2.636 0 00-1.738.36c-.59.33-1.007.98-1.01 1.663.564.14 1.188.096 1.696-.198.665-.341 1.026-1.1 1.052-1.825zm-2.92 2.845a2.647 2.647 0 011.753-.143c-.11.707-.547 1.404-1.232 1.673-.515.23-1.116.222-1.644.04a.17.17 0 00-.033-.014c-.033-.011-.07-.023-.058-.061a1.994 1.994 0 011.214-1.495z\"></path></g><rect x=\".875\" y=\".5\" width=\"30.25\" height=\"29\" rx=\"14.5\" stroke=\"#000\" stroke-opacity=\".1\"></rect>", 2))
  ]))
}
}

})