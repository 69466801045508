export const enum Slugs {
	CITY = 'c',
	TAG = 't',
	DISTRICT = 'd',
	METRO_STATION = 'm',
	CATEGORY = 'ca',
	REGION = 'r',
	FEATURE = 'f',
}

export const SlugSearchParameterMap = new Map<Slugs, string>([
	[Slugs.CITY, 'city'],
	[Slugs.TAG, 'tag'],
	[Slugs.DISTRICT, 'district'],
	[Slugs.METRO_STATION, 'metro-station'],
	[Slugs.CATEGORY, 'category'],
	[Slugs.REGION, 'region'],
	[Slugs.FEATURE, 'feature'],
])
