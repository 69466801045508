import script from "./notification-bell.vue?vue&type=script&setup=true&lang=ts"
export * from "./notification-bell.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import Ripple from 'quasar/src/directives/ripple/Ripple.js';
import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../../node_modules/.pnpm/@quasar+app-webpack@3.13.4_eslint@8.57.0_pinia@2.2.2_typescript@4.9.4_vue@3.5.3_typescript@4._kdplrr3ceeinsy2ecobt4vztby/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QBadge,QCircularProgress,QMenu,QList,QItem,QItemSection,QImg,QIcon,QItemLabel,QSeparator});qInstall(script, 'directives', {Ripple,ClosePopup});
