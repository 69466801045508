import { defineStore } from 'pinia'
import {
	Category,
	City,
	District,
	Feature,
	PublicSalonDto,
} from 'src/common/axios-client/api'
import { API } from 'boot/axios'
import { Notify } from 'quasar'
import { DEFAULT_COUNTRY_ID } from 'src/constants'
import { getPaginationSeed } from 'src/common/seed'

interface State {
	_features: Feature[]
	_categories: Category[]
	_menuCities: Array<City & { districts: District[] }>
	_allCities: City[]
	_salons: PublicSalonDto[]
}

export const usePreFetchStore = defineStore('prefetchStore', {
	state: (): State => ({
		_features: [],
		_categories: [],
		_menuCities: [],
		_allCities: [],
		_salons: [],
	}),

	getters: {},
	actions: {
		async updateFeaturesStore(): Promise<void> {
			await API.refsApi
				.menuControllerCatalogFeatures()
				.then((e) => {
					this._features = e.data
				})
				.catch(() => {
					// TODO i18n
					Notify.create({
						type: 'warning',
						message: 'Не удалось загрузить список услуг',
					})
					return []
				})
		},

		async updateCategoriesStore(): Promise<void> {
			await API.refsApi.refsControllerCategories().then((r) => {
				this._categories = r.data
			})
		},

		async updateCities(): Promise<void> {
			await API.refsApi
				.menuControllerCities(undefined, undefined, DEFAULT_COUNTRY_ID)
				.then(async (r) => {
					// all data
					this._allCities = r.data.map((e) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { districts, ...rest } = e
						return rest
					})

					// only 10 most important
					this._menuCities = r.data.slice(0, 10)
				})
		},

		async updateSalons(): Promise<void> {
			const seed = getPaginationSeed()
			await API.salonsApi.salonControllerFindAll(4, 0, seed).then((r) => {
				this._salons = r.data.items
			})
		},
	},
})
