import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

type Props = {
	size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'health-icon',
  props: {
    size: { default: 'md' }
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createBlock(_component_q_icon, {
    size: props.size,
    name: "health_and_safety",
    color: "green"
  }, null, 8, ["size"]))
}
}

})