import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row self-center" }

import { formatTimeAgo } from 'src/common/utils/format'
import { useNotificationStore } from 'stores/notification.store'
import { onMounted, ref } from 'vue'
import { Notification } from 'src/common/axios-client'
import { SIDEBAR_NOTIFICATIONS_LIMIT } from 'src/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'notification-bell',
  setup(__props) {

const notificationStore = useNotificationStore()

const unreadedNotifications = ref<Notification[]>([])
const isLoading = ref(false)

function getNewNotifications() {
	isLoading.value = true
	notificationStore
		.newNotifications(SIDEBAR_NOTIFICATIONS_LIMIT, 0)
		.then((n) => {
			unreadedNotifications.value = n.items
		})
		.finally(() => {
			isLoading.value = false
		})
}

function markAsSeen(id: string) {
	notificationStore.markAsSeen(id).then(() => getNewNotifications())
}

function getBadgeLabel(n: number): string {
	if (n <= SIDEBAR_NOTIFICATIONS_LIMIT) return String(n)
	return `${SIDEBAR_NOTIFICATIONS_LIMIT}+`
}

onMounted(() => {
	getNewNotifications()
})

return (_ctx: any,_cache: any) => {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _directive_ripple = _resolveDirective("ripple")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    "fab-mini": "",
    flat: "",
    round: "",
    icon: "notifications"
  }, {
    default: _withCtx(() => [
      (unreadedNotifications.value.length > 0)
        ? (_openBlock(), _createBlock(_component_q_badge, {
            key: 0,
            floating: "",
            rounded: "",
            align: "middle",
            color: "accent",
            label: getBadgeLabel(unreadedNotifications.value.length)
          }, null, 8, ["label"]))
        : _createCommentVNode("", true),
      (isLoading.value)
        ? (_openBlock(), _createBlock(_component_q_badge, {
            key: 1,
            floating: "",
            rounded: "",
            align: "middle"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_circular_progress, {
                size: "10px",
                indeterminate: ""
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_q_menu, {
        fit: "",
        self: "top middle",
        "max-width": "400px",
        offset: [0, 5]
      }, {
        default: _withCtx(() => [
          (unreadedNotifications.value.length > 0)
            ? (_openBlock(), _createBlock(_component_q_list, { key: 0 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(unreadedNotifications.value, (notification) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      key: notification.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            (notification.body.image)
                              ? (_openBlock(), _createBlock(_component_q_img, {
                                  key: 0,
                                  src: notification.body.image,
                                  alt: notification.body.title
                                }, null, 8, ["src", "alt"]))
                              : (_openBlock(), _createBlock(_component_q_icon, {
                                  key: 1,
                                  name: "ion-notifications"
                                }))
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, { lines: "1" }, {
                              default: _withCtx(() => [
                                (notification.body.link)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                                      _createElementVNode("a", {
                                        href: notification.body.link
                                      }, _toDisplayString(notification.body.title), 9, _hoisted_2),
                                      _createVNode(_component_q_icon, {
                                        name: "ion-link",
                                        class: "q-ml-xs"
                                      })
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(notification.body.title), 1))
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_q_item_label, { caption: "" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(notification.body.message), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_q_item_label, {
                              caption: "",
                              lines: "1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(formatTimeAgo)(new Date(notification.createdAt).toISOString())), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_item_section, { side: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              flat: "",
                              "fab-mini": "",
                              onClick: _withModifiers(($event: any) => (markAsSeen(notification.id)), ["stop"]),
                              icon: "check"
                            }, null, 8, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)), [
                      [_directive_ripple],
                      [_directive_close_popup]
                    ])
                  }), 128))
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_q_list, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode(" Тут ничего нового ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })),
          _createVNode(_component_q_separator, { size: "1px" }),
          _createVNode(_component_q_list, { style: {"min-width":"100px"} }, {
            default: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_q_item, { clickable: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_router_link, { to: "personal/notifications" }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode(" Смотреть все ")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})