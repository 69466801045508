import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

import { useUiStore } from 'stores/ui.store'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import locales from 'src/i18n'
import { useAuthStore } from 'src/stores/auth.store'


export default /*@__PURE__*/_defineComponent({
  __name: 'language-switch',
  setup(__props) {

const uiStore = useUiStore()
const authStore = useAuthStore()
const { locale } = useI18n({ useScope: 'global' })
const localeOptions = Object.keys(locales).map((key) => ({
	value: key,
	// @ts-ignore
	label: locales[key].locale,
}))

watch(locale, () => {
	uiStore.locale = locale.value
})

onMounted(() => {
	uiStore.i18n.locale = uiStore.locale as any
})

const currentLanguage = computed(() => {
	return localeOptions.find((l) => l.value === locale.value)
})

function setLocale(l: (typeof localeOptions)[number]): void {
	locale.value = l.value
}

function isSelected(l: (typeof localeOptions)[number]): boolean {
	return l.value === locale.value
}

return (_ctx: any,_cache: any) => {
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (!_unref(authStore).isLoggedIn)
    ? (_openBlock(), _createBlock(_component_q_btn, {
        key: 0,
        round: "",
        flat: "",
        label: currentLanguage.value?.value.toUpperCase()
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_menu, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, { style: {"min-width":"100px"} }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(localeOptions), (localeOption) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: localeOption.label
                    }, [
                      (!isSelected(localeOption))
                        ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                            key: 0,
                            class: "language-option",
                            clickable: "",
                            onClick: ($event: any) => (setLocale(localeOption))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(localeOption.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])), [
                            [_directive_close_popup]
                          ])
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label"]))
    : _createCommentVNode("", true)
}
}

})